import React, { useState, useEffect, useMemo } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import * as Ant from "antd";
import Widget, { Font, Color, FontSize } from "./Widget";
import * as Icon from "./Icon";
import { dateFormat } from "../Utils/DateUtil";
import RichTextEditor from "./RichTextEditor";
import { createEditor, Text } from "slate";
import { withReact, Slate, ReactEditor } from "slate-react";
import { withHistory } from "slate-history";
import * as Survey from "../Contexts/SurveyContext";
import escapeHtml from "escape-html";

import { jsx } from "slate-hyperscript";

const deserialize = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx("text", markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName) {
    case "strong":
      nodeAttributes.bold = true;
  }

  const children = Array.from(el.childNodes)
    .map(node => deserialize(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx("text", nodeAttributes, ""));
  }

  console.log("el.nodeName: ", el.nodeName);

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return "\n";
    case "BLOCKQUOTE":
      return jsx("element", { type: "quote" }, children);
    case "P":
      return jsx("element", { type: "paragraph" }, children);
    case "A":
      return jsx(
        "element",
        { type: "link", url: el.getAttribute("href") },
        children
      );
    case "UL":
      return jsx("element", { type: "bulleted-list" }, children);
    case "LI":
      return jsx("element", { type: "list-item" }, children);
    case "H1":
      return jsx("element", { type: "header_one" }, children);
    case "H2":
      return jsx("element", { type: "header_two" }, children);
    default:
      return children;
  }
};

const serialize = node => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    return string;
  }

  const children = node.children.map(n => serialize(n)).join("");

  switch (node.type) {
    case "paragraph":
      return `<p>${children}</p>`;
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case "block-quote":
      return `<blockquote>${children}</blockquote>`;
    case "bulleted-list":
      return `<ul>${children}</ul>`;
    case "heading-one":
      return `<h1>${children}</h1>`;
    case "heading-two":
      return `<h2>${children}</h2>`;
    case "list-item":
      return `<li>${children}</li>`;
    case "numbered-list":
      return `<ol>${children}</ol>`;
    case "bold":
      return `<strong>${children}</strong>`;
    case "code":
      return `<code>${children}</code>`;
    case "italic":
      return `<em>${children}</em>`;
    case "underlined":
      return `<u>${children}</u>`;
    default:
      return children;
  }
};

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

function PrivacyContentEdit({ loading, ...props }) {
  const { visiable, setVisiable } = props;
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const [content, setContent] = useState(initialValue);

  console.log("editor", editor);
  console.log("content", content);

  const privacy = props.privacy;

  function isEmpty(content) {
    if (content.length === 1) {
      if (content[0].children.length === 1) {
        if (content[0].children[0].text === "") {
          return true;
        }
      }
    }
    return false;
  }

  const editorProps = useMemo(() => {
    const isContentBlank = () => {
      try {
        if (Array.isArray(editor.children)) {
          if (editor.children.length > 0) {
            let currNode = editor.children[0];
            while (currNode.children && currNode.children.length > 0) {
              currNode = currNode.children[0];
            }
            if (currNode.text && currNode.text.trim()) {
              return false;
            }
          }
        }
      } catch (err) {
        // console.log("isContentBlank error:", err);
      }
      return true;
    };
    const secureSetContent = content => {
      if (content && Array.isArray(content)) {
        if (content.length > 0) {
          setContent(content);
        } else {
          setContent(initialValue);
        }
      } else if (content && typeof content === "string") {
        setContent([
          {
            type: "paragraph",
            children: [{ text: content }],
          },
        ]);
      } else {
        setContent(initialValue);
      }
    };

    return {
      editor,
      setContent: secureSetContent,
      getContent: () => {
        return editor.children;
      },
      isContentBlank: isContentBlank,
      resetSelection: () => {
        editor.selection = {
          anchor: { path: [0, 0, 0], offset: 0 },
          focus: { path: [0, 0, 0], offset: 0 },
        };
      },
      resetContent: text => {
        // we have to manually handle focus when force setting content,
        // see https://github.com/ianstormtaylor/slate/issues/3477
        editor.selection = {
          anchor: { path: [0, 0, 0], offset: 0 },
          focus: { path: [0, 0, 0], offset: 0 },
        };
        secureSetContent(text);
      },
    };
  }, [editor, content]);

  useEffect(() => {
    editorProps.setContent(content);
  }, [editorProps, content]);

  useEffect(() => {
    // if (props.privacy != null) setContent(JSON.parse(props.privacy.content));
    if (props.privacy != null) {
      // 修改一下，目前是存為html
      // 兼容一下json
      var jsonContent = null;
      try {
        jsonContent = JSON.parse(props.privacy.content);
      } catch (e) {}

      if (jsonContent === null) {
        // parse 失敗
        const document = new DOMParser().parseFromString(
          props.privacy.content,
          "text/html"
        );
        const content = deserialize(document.body);

        setContent(content);
      } else {
        setContent(jsonContent);
      }
    }
  }, [props.privacy]);

  async function updatePrivacyContent() {
    try {
      if (props.privacy == null) return;
      const privacyContent = props.privacy;
      console.log("ttt", JSON.stringify(editorProps.getContent()));
      const payload = {
        PrivacyId: privacyContent.id,
        Content: JSON.stringify(editorProps.getContent()),
        // Content: serialize(editor),
      };
      const resp = await Survey.Actions.updatePrivacy(payload);
      if (resp.code === "200") {
        props.updatePrivacyList();
      }
    } catch (err) {
      console.log("clone privacy content error = ", err);
    }
  }

  async function enablePrivacyContent(privacyId) {
    try {
      const resp = await Survey.Actions.setPrivacyEnable(privacyId);
      if (resp.code === "200") {
        props.privacy.enable = 1;
        props.updatePrivacyList();
      }
    } catch (err) {
      console.log("enable privacy content error = ", err);
    }
  }

  return (
    <Wrapper>
      <Ant.Modal
        title="Modal 860px width"
        centered
        visible={visiable}
        onOk={() => {
          updatePrivacyContent();
          setVisiable(false);
        }}
        onCancel={() => {
          setVisiable(false);
        }}
        cancelText={"取消"}
        okText={"確認修改"}
        width={860}
        getContainer={false}
        okButtonProps={{
          disabled:
            props.privacy != null && props.privacy.enable === 1 ? true : false,
        }}
      >
        <Ant.Row className="header-block">
          <div className="header-version">
            版本v{privacy != null ? privacy.version : ""}
          </div>
          <div className="header-updated">
            最後更新日期{" "}
            {privacy != null ? dateFormat(privacy.updDateTime) : ""}
          </div>
        </Ant.Row>
        <Ant.Row className="title-block">
          <Font.LargeTitle>
            {privacy != null ? privacy.title : ""}
          </Font.LargeTitle>
          <div className="status-display">
            {privacy != null && privacy.enable === 1 ? (
              <>
                <DotIcon className="active"></DotIcon>
                <Font.Small style={{ color: "#389e0d" }}>啓用</Font.Small>
              </>
            ) : (
              <>
                <DotIcon></DotIcon>
                <Font.Small>停用</Font.Small>
              </>
            )}
          </div>
          <Ant.Button
            style={{
              minWidth: 112,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: Color.GreyWhite,
              backgroundColor: Color.Purple,
            }}
            onClick={() => {
              if (props.privacy != null) enablePrivacyContent(props.privacy.id);
            }}
            disabled={
              props.privacy != null && props.privacy.enable === 1 ? true : false
            }
          >
            啓用內容
          </Ant.Button>
        </Ant.Row>
        <Ant.Divider />
        <Slate
          editor={editor}
          value={content}
          onChange={value => {
            if (isEmpty(value) && !isEmpty(content)) {
              ReactEditor.blur(editor);
            }
            setContent(value);
          }}
        >
          <RichTextEditor
            readOnly={
              props.privacy != null && props.privacy.enable === 1 ? true : false
            }
            placeholder="請輸入內容"
            editor={editorProps.editor}
            endingPage={true}
            showPrivacyTool={true}
            isPrivacy={true}
          ></RichTextEditor>
        </Slate>
      </Ant.Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .ant-modal-content {
    border-radius: 5px;
  }
  .ant-modal-header {
    // 棄用ant header
    display: none;
    height: 32px;
    background-color: #dcdaef;
    padding: 0;
  }
  .header-block {
    align-content: center;
    border-radius: 5px 5px 0 0;
    height: 32px;
    margin: -24px -24px 21px -24px;
    background-color: #dcdaef;
    font-family: PingFangTC;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #756ec2;
    display: flex;
    justify-content: space-between;
    .header-version {
      margin-left: 27px;
    }
    .header-updated {
      margin-right: 56px;
    }
  }
  .ant-modal-title {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #756ec2;
  }
  .ant-modal-close {
    margin: 4px 4px 0 0;
  }
  .ant-modal-close-x {
    height: 24px;
    width: 24px;
    line-height: 24px;
  }
  .title-block {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    align-items: center;
    .status-display {
      justify-content: end;
      margin-right: 8px;
      display: flex;
      align-items: center;
    }
  }
`;

const DotIcon = styled.div`
  width: 8px;
  height: 8px;
  margin: 7px 8px 7px 0;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.45);
  &.active {
    color: #389e0d;
    background-color: #389e0d;
  }
`;

export default PrivacyContentEdit;
