import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import * as Ant from "antd";
import Widget, { Font, Color, FontSize } from "./Widget";
import * as Survey from "../Contexts/SurveyContext";
import * as Icon from "./Icon";
import { dateFormat } from "../Utils/DateUtil";
import { ExclamationCircleFilled } from "@ant-design/icons";

function PrivacyList({ loading, ...props }) {
  const { surveyStatusList, fetchListData } = props;
  const [popoverVisibleIdx, setPopoverVisibleIdx] = useState(null);
  const [saveToTemplateData, setSaveToTemplateData] = useState(null);
  const [clonePrivacyId, setClonePrivacyId] = useState(null);
  const [cloneNewPrivacyTitle, setCloneNewPrivacyTitle] = useState("");
  const [saveToTemplateTitle, setSaveToTemplateTitle] = useState("");
  const [sendCopyData, setSendCopyData] = useState(null);
  const [sendCopyToUser, setSendCopyToUser] = useState(null);
  const [userList, setUserList] = useState([]);

  const privacys = props.privacys;

  useEffect(() => {
    async function fetchUserList() {
      try {
        let list = await Survey.Actions.fetchAccountList();
        setUserList(list);
      } catch (err) {
        //
        setUserList([]);
      }
    }

    if (sendCopyData) {
      fetchUserList();
    } else {
      //
    }
  }, [sendCopyData]);

  async function enablePrivacyContent(privacyId) {
    try {
      const resp = await Survey.Actions.setPrivacyEnable(privacyId);

      if (resp.code === "200") {
        fetchListData();
      }
    } catch (err) {
      console.log("enable privacy content error = ", err);
    }
  }

  async function deletePrivacyVersion(privacyId) {
    try {
      const resp = await Survey.Actions.deletePrivacy(privacyId);

      if (resp.code === "200") {
        fetchListData();
      }
    } catch (err) {
      console.log("delete privacy content error = ", err);
    }
  }

  async function clonePrivacyVersion(privacyId, title) {
    try {
      const resp = await Survey.Actions.clonePrivacy(privacyId, title);
      if (resp.code === "200") {
        fetchListData();
      }
    } catch (err) {
      console.log("clone privacy content error = ", err);
    }
  }

  const columns = [
    {
      title: "版次",
      key: "version",
      width: 80,
      align: "center",
      render: (_, record) => <Font.Body>{`${record.version}`}</Font.Body>,
    },
    {
      title: "標題",
      key: "title",
      width: 300,
      render: (_, record) => (
        <Widget.FlexCol>
          <Font.Body>{record.title}</Font.Body>
        </Widget.FlexCol>
      ),
    },
    {
      title: "狀態",
      key: "enable",
      width: 150,
      // render: (_, record) => {
      //   const [text, color] = Survey.Utils.getStatusLabel(
      //     record,
      //     surveyStatusList
      //   );
      //   return (
      //     <StatusLabel style={{ backgroundColor: color }}>
      //       <Font.Small style={{ color: Color.GreyBlack }}>
      //         {text || "-"}
      //       </Font.Small>
      //     </StatusLabel>
      //   );
      // },
      render: (_, record) => (
        <Font.Body>
          <StatusDisplay>
            {record.enable != null && record.enable === 1 ? (
              <>
                <DotIcon className="active"></DotIcon>
                <Font.Small style={{ color: "#389e0d" }}>啓用</Font.Small>
              </>
            ) : (
              <>
                <DotIcon></DotIcon>
                <Font.Small>停用</Font.Small>
              </>
            )}
          </StatusDisplay>
        </Font.Body>
      ),
    },
    {
      title: "建立時間",
      key: "createDateTime",
      width: 80,
      align: "right",
      render: (_, { createDateTime }) => (
        <Font.Body>{dateFormat(createDateTime) || "-"}</Font.Body>
      ),
    },
    {
      title: "最後更新時間",
      key: "updDateTime",
      width: 100,
      // align: "left",
      render: (_, { updDateTime }) => (
        <Font.Body>{dateFormat(updDateTime) || "-"}</Font.Body>
      ),
    },
    {
      title: "發佈時間",
      key: "publishDateTime",
      width: 100,
      render: (_, { publishDateTime }) => (
        <Font.Body>
          {publishDateTime != null ? dateFormat(publishDateTime) || "-" : "-"}
        </Font.Body>
      ),
    },
    {
      title: () => <div style={{ color: Color.Purple }}>編輯</div>,
      key: "edit",
      width: 36,
      align: "center",
      render: (_, record) => (
        <div
          style={{ width: 24, cursor: "pointer" }}
          onClick={() => {
            props.openShowEditContentModal(record);
          }}
        >
          <Icon.Edit size={FontSize.subTitle} color={Color.Purple} />
        </div>
      ),
    },
    {
      title: () => <div style={{ color: Color.Purple }}>更多</div>,
      key: "more",
      width: 36,
      align: "center",
      render: (_, record, idx) => (
        <Ant.Popover
          placement="bottomLeft"
          visible={popoverVisibleIdx === idx}
          onVisibleChange={visible =>
            setPopoverVisibleIdx(visible ? idx : null)
          }
          content={
            <div style={{ minWidth: 122, marginLeft: -16, marginRight: -16 }}>
              <Font.Body
                style={{
                  color: Color.GreyBlack,
                  cursor: "pointer",
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
                className="hover-mask"
                onClick={() => {
                  setPopoverVisibleIdx(null);
                  setClonePrivacyId(record.id);
                }}
              >
                複製
              </Font.Body>
              <Font.Body
                style={{
                  color: Color.GreyBlack,
                  cursor: "pointer",
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
                className="hover-mask"
                onClick={() => {
                  Ant.Modal.confirm({
                    title: "確認要刪除此內容？",
                    content: "一旦刪除，此邏輯規則不可復原",
                    okText: "取消",
                    okType: "primary",
                    onOk: () => {},
                    cancelButtonProps: { type: "ghost" },
                    cancelText: "確認刪除",
                    onCancel: async () => {
                      setPopoverVisibleIdx(null);
                      deletePrivacyVersion(record.id);
                    },
                    icon: (
                      <ExclamationCircleFilled
                        color={Color.LightGold}
                        size={20}
                      />
                    ),
                  });
                }}
              >
                刪除
              </Font.Body>
              {record.enable != null && record.enable === 2 ? (
                <Font.Body
                  style={{
                    color: Color.GreyBlack,
                    cursor: "pointer",
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingLeft: 16,
                    paddingRight: 16,
                  }}
                  className="hover-mask"
                  onClick={() => {
                    setPopoverVisibleIdx(null);
                    enablePrivacyContent(record.id);
                  }}
                >
                  啓用內容
                </Font.Body>
              ) : (
                undefined
              )}
            </div>
          }
          trigger="click"
        >
          <div style={{ width: 24 }}>
            <Icon.Ellipsis
              size={FontSize.largeTitle}
              color={Color.Purple}
              css="font-size: 24px;"
            />
          </div>
        </Ant.Popover>
      ),
    },
  ];

  return (
    <>
      {
        <Wrapper>
          <Font.LargeTitle>個資聲明內文版本管理</Font.LargeTitle>
          <Ant.Table
            dataSource={privacys}
            columns={columns}
            rowKey="id"
            loading={loading}
          />

          <Ant.Modal
            visible={!!clonePrivacyId}
            title="名稱"
            bodyStyle={{ padding: 0, margin: 0 }}
            onCancel={() => {
              setClonePrivacyId(null);
              setCloneNewPrivacyTitle("");
            }}
            okText={"確定"}
            onOk={async () => {
              if (!cloneNewPrivacyTitle) {
                return;
              }
              setPopoverVisibleIdx(null);
              clonePrivacyVersion(clonePrivacyId, cloneNewPrivacyTitle);
              setClonePrivacyId(null);
              setCloneNewPrivacyTitle("");
            }}
            cancelText={"取消"}
          >
            <div style={{ padding: "34px 32px 38px 32px" }}>
              <Ant.Input
                value={cloneNewPrivacyTitle}
                onChange={e => setCloneNewPrivacyTitle(e.target.value)}
              />
            </div>
          </Ant.Modal>

          <Ant.Modal
            visible={!!sendCopyData}
            title="傳送複本"
            bodyStyle={{ padding: 0, margin: 0 }}
            onCancel={() => {
              setSendCopyData(null);
              setSendCopyToUser(null);
            }}
            okText={"確定"}
            onOk={async () => {
              if (!sendCopyToUser) {
                return;
              }
              await Survey.Actions.sendCopyToUser(
                sendCopyData.id,
                sendCopyToUser
              );
              setSendCopyData(null);
              setSendCopyToUser(null);
            }}
            cancelText={"取消"}
          >
            <div>
              <Widget.FlexRow
                style={{
                  marginBottom: 14,
                  marginTop: 16,
                  paddingLeft: 32,
                  paddingRight: 18,
                }}
              >
                <Font.Head style={{ color: Color.GreyBlack }}>將</Font.Head>
                <Font.Head style={{ color: Color.Purple, marginLeft: 16 }}>
                  {sendCopyData?.title || ""}
                </Font.Head>
              </Widget.FlexRow>
              <Widget.FlexRow
                style={{ marginBottom: 26, paddingLeft: 32, paddingRight: 18 }}
              >
                <Font.Head style={{ color: Color.GreyBlack }}>
                  傳送一份複本給
                </Font.Head>
                <Ant.Select
                  style={{ marginLeft: 16, flex: 1 }}
                  value={sendCopyToUser}
                  placeholder={"選擇使用者"}
                  onChange={v => {
                    setSendCopyToUser(v);
                  }}
                >
                  {userList.map((u, idx) => (
                    <Ant.Select.Option key={idx} value={u.UserId}>
                      {u.name}
                    </Ant.Select.Option>
                  ))}
                </Ant.Select>
              </Widget.FlexRow>
            </div>
          </Ant.Modal>
          <Widget.CopyrightFooter />
        </Wrapper>
      }
    </>
  );
}

const Wrapper = styled.div`
  .ant-table-pagination.ant-pagination {
    padding-top: 24px;
    padding-bottom: 0;
  }
  .ant-pagination-item {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    border: solid 1px rgba(0, 0, 0, 0.15);
    a {
      background-color: #ffffff;
      color: rgba(0, 0, 0, 0.65);
      transition: all 0.25s linear;
    }
    &.ant-pagination-item-active a {
      background-color: #534ab3;
      color: #ffffff;
    }
  }
  & th.ant-table-cell {
    padding-top: 15px;
    padding-bottom: 4px;
    padding-left: 0;
    padding-right: 0;
    color: rgba(15, 14, 35, 0.65);
    font-weight: 400;

    &:first-child {
      padding-left: 12px;
      padding-right: 20px;
      width: 60px;
    }
    &:nth-child(2) {
      padding-right: 20px;
      width: 330px;
    }
    &:nth-child(3) {
      padding-right: 20px;
      padding-left: 11px;
      width: 144px;
    }
    &:nth-child(4) {
      padding-left: 18px;
      width: 60px;
    }
    &:nth-child(5) {
      padding-left: 90px;
      padding-right: 20px;
      width: 217px;
      text-align: left;
    }
    &:nth-child(6) {
      width: 168px;
    }
    &:nth-child(7) {
      padding-right: 32px;
      width: 60px;
    }
    &:nth-child(8) {
      padding-right: 32px;
      width: 60px;
    }
    &:nth-child(9) {
      padding-right: 14px;
      width: 44px;
    }
  }
  & td.ant-table-cell {
    height: 56px;
    padding: 0;

    &:first-child {
      padding-left: 12px;
      padding-right: 20px;
      width: 60px;
      text-align: center;
    }
    &:nth-child(2) {
      padding-right: 20px;
      width: 330px;
    }
    &:nth-child(3) {
      padding-right: 20px;
      width: 144px;
    }
    &:nth-child(4) {
      width: 60px;
    }
    &:nth-child(5) {
      padding-left: 90px;
      padding-right: 20px;
      width: 217px;
      text-align: left;
    }
    &:nth-child(6) {
      width: 168px;
      /* div {
        color: rgba(0, 0, 0, 0.45);
      } */
    }
    &:nth-child(7) {
      padding-right: 32px;
      width: 60px;
    }
    &:nth-child(8) {
      padding-right: 32px;
      width: 60px;
    }
    &:nth-child(9) {
      padding-right: 14px;
      width: 44px;
    }
  }
  .ant-table-cell {
    cursor: pointer;
  }
`;

const StatusLabel = styled.div`
  display: inline-block;
  border-radius: 18.5px;
  padding: 0px 11px;
  font-size: 13px;
  line-height: 22px;
  height: 22px;
`;

const StatusDisplay = styled.div`
  justify-content: start;
  display: flex;
  align-items: center;
`;

const DotIcon = styled.div`
  width: 8px;
  height: 8px;
  margin: 7px 8px 7px 0;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.45);
  &.active {
    color: #389e0d;
    background-color: #389e0d;
  }
`;

export default PrivacyList;
