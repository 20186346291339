import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { connect } from "react-redux";
import { withPage } from "../PageContainer";
import LoginForm from "../Components/LoginForm";
import * as Survey from "../Contexts/SurveyContext";
import PrivacyList from "../Components/PrivacyList";
import PrivacyContentEdit from "../Components/PrivacyContentEdit";
const queryString = require("query-string");
let windowGlobal = typeof window !== "undefined" && window;

/**
 * 填寫站台隱私權宣告頁面
 * @param {profile} obj 登入用戶物件
 * @return {PrivacyPage}
 */
function PrivacyPage({ profile, ...props }) {
  const [loading, setLoading] = useState(false);
  const [privacys, setPrivacys] = useState([]);
  const [selectPrivacy, setSelectPrivacy] = useState(null);
  const [updater, setUpdater] = useState(0);
  const [_, setLocalToken] = useState(() => {
    windowGlobal = typeof window !== "undefined" && window;
    const stickyValue =
      windowGlobal && windowGlobal.localStorage.getItem("token");
    return stickyValue !== null ? stickyValue : profile && profile.token;
  });
  windowGlobal = typeof window !== "undefined" && window;
  let storetoken =
    windowGlobal && windowGlobal.localStorage.getItem("token")
      ? windowGlobal.localStorage.getItem("token")
      : profile && profile.token;
  let localprofile =
    windowGlobal && windowGlobal.localStorage.getItem("profile")
      ? windowGlobal.localStorage.getItem("profile")
      : profile && profile;
  const { appActions } = props;
  let uid =
    queryString.parse(props.location.search, { decode: false }).uid ===
    undefined
      ? ""
      : queryString.parse(props.location.search, { decode: false }).uid;
  const [ssoUid, setSsoUid] = useState(null);

  const [contentEditVisiable, setContentEditVisiable] = useState(false);

  useEffect(() => {
    windowGlobal = typeof window !== "undefined" && window;
    storetoken =
      windowGlobal && windowGlobal.localStorage.getItem("token")
        ? windowGlobal.localStorage.getItem("token")
        : profile && profile.token;
    localprofile =
      windowGlobal && windowGlobal.localStorage.getItem("profile")
        ? windowGlobal.localStorage.getItem("profile")
        : profile && profile;
    storetoken && setLocalToken(storetoken);

    if (localprofile && storetoken) {
      fetchData();
    }
  }, [profile, updater, ssoUid]);

  //判斷如為sso則不轉導登入而是直接取得token&userinfo
  //crmsurvey.cht.com.tw 內網
  //ecrmsurvey.cht.com.tw 外網
  // console.log('props.location===>',props.location);

  async function fetchData() {
    setLoading(true);
    try {
      setPrivacys(await Survey.Actions.fetchPrivacyList(profile));
    } catch (ex) {
      // alert("系統異常，請洽系統管理員");
      throw ex;
    }
    setLoading(false);
  }

  useEffect(() => {
    windowGlobal = typeof window !== "undefined" && window;
    storetoken =
      windowGlobal && windowGlobal.localStorage.getItem("token")
        ? windowGlobal.localStorage.getItem("token")
        : profile && profile.token;
    localprofile =
      windowGlobal && windowGlobal.localStorage.getItem("profile")
        ? windowGlobal.localStorage.getItem("profile")
        : profile && profile;

    storetoken && setLocalToken(storetoken);
    uid =
      queryString.parse(props.location.search, { decode: false }).uid ===
      undefined
        ? ""
        : queryString.parse(props.location.search, { decode: false }).uid;
    let hostname = props.location.host;
    // check host url to set the token Valid by state.
    //判斷是否在可運行的網址環境並確認token狀態
    if (
      (hostname === "localhost:8000" || // 本地端環境
      hostname === "localhost:9000" || // 本地端環境
      hostname === "139.162.67.143:8080" || // 測試網址環境
      hostname === "ecrmsurvey.cht.com.tw" || // 中華正式環境
        hostname === "crmsurvey.cht.com.tw") && // 中華正式環境
      uid !== "" && // uid 不得為空
      ssoUid !== uid // ssouid 與 uid 不得相同
    ) {
      try {
        appActions.ssoLogin(uid);
        setSsoUid(uid);
        setUpdater(updater + 1);
      } catch (err) {}
    } else {
      // console.log("ssoUid", ssoUid);
      // console.log("sso bypass | ", hostname);
    }
  }, [uid, profile]);

  useEffect(() => {
    windowGlobal = typeof window !== "undefined" && window;
    let tokenck = windowGlobal && windowGlobal.localStorage.getItem("token");
    let profileck =
      windowGlobal && windowGlobal.localStorage.getItem("profile");

    if (profile && !tokenck && !profileck) {
      // appActions.logout();
    } else if (tokenck) {
      Survey.Actions.setInternalLocalStorageToken(true);
    }
  }, [profile, updater]);

  if (!storetoken || !localprofile) {
    return (
      <LoginPage>
        <div className="LoginForm-Wrapper">
          <LoginForm />
        </div>
      </LoginPage>
    );
  }

  return (
    <Wrapper>
      <div className="content">
        <PrivacyList
          fetchListData={fetchData}
          privacys={privacys}
          loading={loading}
          openShowEditContentModal={survey => {
            setSelectPrivacy(survey);
            setContentEditVisiable(true);
          }}
        />
        <PrivacyContentEdit
          visiable={contentEditVisiable}
          setVisiable={setContentEditVisiable}
          privacy={selectPrivacy}
          updatePrivacyList={fetchData}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fafafa;
  width: 100vw;
  min-height: calc(100vh - 64px);

  & > .content {
    max-width: 1128px;
    margin: 0 auto;
    padding: 20px 0;
  }
`;

const LoginPage = styled.div`
  width: 100%;
  height: 100%;

  & > .LoginForm-Wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(PrivacyPage)
);
